import React from "react";

function Reviews() {
  const stars = Array(5).fill(0);

  return (
    <div className="pt-10 relative hidden lg:block ">
      <div className="relative">
        <div className="relative z-10">
          <div className="bg-white text-black p-5 rounded-lg relative max-w-3xl shadow-2xl shadow-blue-600/10">
            <div className="w-full rounded-lg p-2">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/google.svg"
                  alt="Google Logo"
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "10px",
                  }}
                />
                {stars.map((_, index) => (
                  <img
                    key={index}
                    src="/star.svg"
                    alt={`Star ${index + 1}`}
                    style={{ width: "20px", height: "20px" }}
                  />
                ))}
              </div>
            </div>
            <br></br>
            <p className="text-sm italic">
              I have been dealing with Ben Venter for many years. Ben has been
              instrumental in providing me with strategic financial advice. His
              belief in a combined advice approach with accountants has provided
              me with the best solutions. In addition, EEA Advisory’s flexible
              operating hours, opening as early as 7:30 am on weekdays, have
              been incredibly convenient for me. It’s clear that they value
              their clients’ time and strive to provide timely service.
            </p>
            <div>
              <h2 className="font-bold">Vaun Turner</h2>
            </div>
          </div>
        </div>
        <div className="pt-10">
          <div className="bg-white text-black p-5 rounded-lg max-w-3xl shadow-2xl shadow-blue-600/10">
            <div className="w-full   rounded-lg p-2">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/google.svg"
                  alt="Google Logo"
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "10px",
                  }}
                />
                {stars.map((_, index) => (
                  <img
                    key={index}
                    src="/star.svg"
                    alt={`Star ${index + 1}`}
                    style={{ width: "20px", height: "20px" }}
                  />
                ))}
              </div>
            </div>
            <br></br>
            <p className="text-sm italic">
              EEA Advisory is a highly recommended firm of public accountants
              and tax experts based in Capalaba. They have been providing
              exemplary services to us for many years. Their team is dedicated
              to offering sophisticated accounting solutions and strategic
              business guidance, tailored to meet our complex business structure
            </p>

            <div>
              <h2 className="font-bold">Ilona Tilleyshort</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
