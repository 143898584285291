import * as yup from "yup";

const schema = yup.object().shape({
  rating: yup
    .number()
    .required("Rating is required")
    .min(1, "Rating must be at least 1"),
  firstName: yup.string().required("First Name is required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .matches(/^\d+$/, "Phone number must be digits only"),
  review: yup
    .string()
    .required("Review is required")
    .min(10, "Review must be at least 10 characters long"),
});

export default schema;
