import React, { useState, forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactStars from "react-rating-stars-component";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import schema from "../schema/validationSchema";

// Create a forwardRef wrapper for ReactStars
const ReactStarsWithRef = forwardRef((props, ref) => (
  <ReactStars {...props} innerRef={ref} />
));

const MySwal = withReactContent(Swal);

function ReviewForm() {
  const [ratingSelected, setRatingSelected] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [rating, setRating] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setRating(data.rating);
    setSubmitted(true);
    // Send data to Lambda function
    // await sendToLambda(data, "negative_review");

    if (data.rating < 4) {
      MySwal.fire({
        title: "Processing your feedback...",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      // Call the API and handle the response
      sendToLambda(data, "negative")
        .then(() => {
          // Hide the loading indicator and show the success message
          MySwal.fire({
            title:
              "Thank you for your feedback! You can close this window now.",
            icon: "success",
            confirmButtonText: "Close",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
          }).then(() => {
            window.close();
          });
        })
        .catch((error) => {
          // Handle any errors
          MySwal.fire({
            title: "Error",
            text: "Sorry ! There was a problem sending your feedback. Please try again later.",
            icon: "error",
            confirmButtonText: "Close",
            allowOutsideClick: true,
            allowEscapeKey: true,
            showConfirmButton: true,
          });
          console.error("Error sending feedback:", error);
        });
    } else {
      MySwal.fire({
        title: "Just one more favour, please 😊",
        html: `
              <p style="font-size:12px;">We would greatly appreciate it if you could take a moment to post your review on Google. You can use your personal Gmail or any other email associated with a Google account. <strong>You don't need a <span style="color:red"> Gmail account </span>, just a <span style="color:green"> Google account </span> linked to your email.</strong></p>
              <br>
              <p style="font-size:12px; color:orange;">If you're busy now, click "I'm in a hurry" and we'll remind you later.</p>
              <img src="/help.png" alt="Google Review Guide" style="width: 100%; margin-top: 10px;"/>
            `,
        icon: "success",
        showCancelButton: true,
        cancelButtonText: "I'm in a hurry",
        confirmButtonText: "Copy review and post in Google review",
      }).then((result) => {
        if (result.isConfirmed) {
          handleCopyAndRedirect(data);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          showNotificationForLaterContact(data);
        }
      });
    }
  };

  const handleCopyAndRedirect = (data) => {
    navigator.clipboard.writeText(data.review).then(() => {
      MySwal.fire({
        title: "Processing your feedback...",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      // Call the API and handle the response
      sendToLambda(data, "positive")
        .then(() => {
          Swal.fire({
            title: "Thank you!",
            text: "Review has been copied to clipboard, please wait!",
            icon: "success",
            backdrop: "rgba(0, 0, 0, 0.5)", // Dark overlay
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            timer: 2000, // Auto close after 2 seconds
          }).then(() => {
            window.location.href = "https://g.page/r/CQnoeYxrU0hSEBM/review";
            console.log("Redirecting");
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            text: "Please notify EEA, something went wrong!",
            icon: "error",
            backdrop: "rgba(0, 0, 0, 0.5)", // Dark overlay
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            timer: 2000, // Auto close after 2 seconds
          });
          console.error("Could not copy text: ", err);
        });
    });
  };

  const showNotificationForLaterContact = (data) => {
    // Implement your email reminder functionality here
    console.log("Triggering email reminder with data:", data);
    MySwal.fire({
      title: "Processing your feedback...",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    // Call the API and handle the response
    sendToLambda(data, "positive_no_google").then(() => {
      Swal.fire({
        title: "Thank you !",
        text: "We will remind you later to post your review. You can close this window now.",
        icon: "success",
        backdrop: "rgba(0, 0, 0, 0.5)", // Dark overlay
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        // Auto close after 5 seconds
      }).catch((err) => {
        Swal.fire({
          title: "Error",
          text: "Please notify EEA, something went wrong!",
          icon: "error",
          backdrop: "rgba(0, 0, 0, 0.5)", // Dark overlay
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          timer: 2000, // Auto close after 2 seconds
        });
        console.error("Error after making a positive_no_google_review: ", err);
      });
    });
  };

  const sendToLambda = async (data, reviewType) => {
    try {
      data.review_type = reviewType;
      const response = await fetch(
        "https://vzxv41dbpb.execute-api.ap-southeast-2.amazonaws.com/production/eeaReviewHandler",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) throw new Error("Failed to send data to Lambda");

      const result = await response.json();
      console.log("Request Posted:", result);

      return result;
    } catch (error) {
      console.error("Error sending data to Lambda:", error);
      throw error;
    }
  };

  return (
    <div className="relative isolate overflow-hidden flex flex-col justify-center items-center">
      <div className="mx-auto max-w-xl px-2 lg:px-8 m-10 max-h-max flex flex-col justify-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-3 p-6 bg-white rounded-lg shadow-xl border border-cyan-500 shadow-slate-500/30 w-full lg:min-w-[500px]"
        >
          <div className="sm:col-span-3">
            <div>
              <h3 className="text-center font-bold text-2xl mb-5">
                Leave us a review{" "}
              </h3>
              <p></p>
              <hr className="mb-2"></hr>
            </div>
            <label
              htmlFor="rating"
              className="block text-sm font-medium leading-6 text-center text-gray-900 mb-2"
            >
              How would you rate your experience with us?
            </label>
            <div className="mt-2 flex items-center justify-center">
              <Controller
                name="rating"
                control={control}
                render={({ field }) => (
                  <ReactStarsWithRef
                    {...field}
                    count={5}
                    size={36}
                    activeColor="#ffd700"
                    value={field.value || 0}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                      setRatingSelected(newValue > 0);
                    }}
                    className="mb-4"
                  />
                )}
              />
              {errors.rating && (
                <p className="text-red-600 text-sm">{errors.rating.message}</p>
              )}
            </div>
          </div>

          {ratingSelected && (
            <>
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="firstName"
                    id="first-name"
                    {...register("firstName")}
                    className={`block w-full rounded-md border border-gray-300 py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6 ${
                      errors.firstName ? "ring-red-500" : ""
                    }`}
                  />
                  {errors.firstName && (
                    <p className="text-red-600 text-sm">
                      {errors.firstName.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    {...register("email")}
                    className={`block w-full rounded-md border border-gray-300 py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6 ${
                      errors.email ? "ring-red-500" : ""
                    }`}
                  />
                  {errors.email && (
                    <p className="text-red-600 text-sm">
                      {errors.email.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Phone Number
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="phoneNumber"
                    id="phone-number"
                    autoComplete="tel"
                    inputMode="numeric"
                    {...register("phoneNumber")}
                    className={`block w-full rounded-md border border-gray-300 py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6 ${
                      errors.phoneNumber ? "ring-red-500" : ""
                    }`}
                  />
                  {errors.phoneNumber && (
                    <p className="text-red-600 text-sm">
                      {errors.phoneNumber.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="review"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Review
                </label>
                <div className="mt-2">
                  <textarea
                    name="review"
                    id="review"
                    rows={5}
                    {...register("review")}
                    className={`block w-full rounded-md border border-gray-300 py-2 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-200 sm:text-sm sm:leading-6 ${
                      errors.review ? "ring-red-500" : ""
                    }`}
                  />
                  {errors.review && (
                    <p className="text-red-600 text-sm">
                      {errors.review.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="pt-5">
                <button
                  type="submit"
                  className="w-full inline-flex items-center justify-center px-3 py-3 text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm"
                >
                  Submit Review
                </button>
              </div>
            </>
          )}
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ReviewForm;
