import React from "react";
import "./App.css";
import ReviewForm from "./components/ReviewForm";
import Reviews from "./components/Reviews";

function App() {
  return (
    <div className="flex flex-col lg:flex-row w-full bg-white-700 min-h-screen relative">
      {/* <div id="overlay" className="overlay"></div> */}

      <div className="p-5 lg:p-10 bg-slate-900 w-full lg:w-1/2 text-white relative ">
        <div
          className="absolute inset-x-0 top-50 transform-gpu overflow-hidden blur-3xl hidden lg:block"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(10%-11rem)] aspect-[1155/678] w-[136.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#dd5c5c] to-[#01eeff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <img src="/logo.png" alt="LOGO" width={150} />
        <div className="p-2 lg:p-10  h-full flex flex-col items-start lg:items-center justify-start lg:justify-center">
          <div className="flex pt-5 lg:pt-5">
            <h3 className="font-mono">Your words matter</h3>
            <span>&nbsp;-------</span>
          </div>
          <hr className="my-3"></hr>
          <div className="">
            <h1 className="text-2xl lg:text-3xl font-bold text-cyan-300">
              We would love to hear about your experience!
            </h1>
          </div>
          <Reviews />
          <div className="pt-10 lg:flex gap-4 hidden pb-10">
            <a
              className="underline"
              href="https://eea-advisory.com.au/contact-us/"
            >
              Contact Us
            </a>
            <a className="underline" href="https://eea-advisory.com.au/">
              Visit Our Website
            </a>
            <p>V 1.0.1.234</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center w-full lg:w-1/2 h-auto lg:min-h-screen bg-white overflow-hidden">
        <ReviewForm />
      </div>
    </div>
  );
}

export default App;
